:root {
    --Grey-Bg: #1E1E1E;
    --Text-color: #ffffff;
    --Logo-color: #BC0000;
}

.aboutSection {
    .heading {
        h1 {
            color: var(--Logo-color);
            margin-left: 30px;
            font-weight: 700;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 400px;


        p {
            font-size: 20px;
            font-weight: 600;
            width: 600px;
            opacity: 0.8;
        }
    }
}