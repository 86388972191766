:root {
  --Grey-Bg: #1E1E1E;
  --Text-color: #ffffff;
  --Logo-color: #BC0000;
}

.clientSection .heading h1 {
  color: var(--Logo-color);
  margin-left: 30px;
  font-weight: 700;
}

.clientSection .content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 300px;
  font-weight: normal;
}

.client-slide img {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .client-slide img:hover {
    transform: scale(1.05);
  }
  
  