:root {
  --Grey-Bg: #1E1E1E;
  --Text-color: #ffffff;
  --Logo-color: #BC0000;
}

.workSection {
  background-color: #ffffff;
  padding-bottom: 30px;
}
.workSection .heading {
  color: var(--Logo-color);
  margin-left: 30px;
  font-weight: 700;
}
.workSection .servi {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.workSection .servi .ourWork {
  border: 2px solid rgba(79, 76, 76, 0.45);
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);
  display: flex;
  flex-direction: row;
  background-color: var(--Text-color);
  width: 300px;
  border-radius: 20px;
  height: 100%;
  background-color: #F3F3F3;
}
.workSection .servi .ourWork .imgdiv img {
  margin-top: 20px;
  margin-left: 10px;
}
.workSection .servi .ourWork .works {
  margin-left: 15px;
  height: 280px;
  margin-right: 10px;
}
.workSection .servi .ourWork .works .workInfo {
  font-weight: 600;
  color: #5d5555;
  margin-left: 5px;
}/*# sourceMappingURL=Work.css.map */