:root {
  --Grey-Bg: #1E1E1E;
  --Text-color: #ffffff;
  --Logo-color: #BC0000;
}

.navBarSection {
  background-color: var(--Grey-Bg);
}
.navBarSection .header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.navBarSection .header .logoDiv {
  display: flex;
  flex-direction: row;
}
.navBarSection .header .logoDiv .imgLogo {
  height: 80px;
  width: 80px;
  margin-left: 10px;
}
.navBarSection .header .logoDiv .logoName {
  display: flex;
  flex-direction: column;
  color: var(--Logo-color);
  margin-left: 15px;
}
.navBarSection .header .logoDiv .logoName h1 {
  color: var(--Logo-color);
  font-size: 30px;
  font-weight: 700;
  margin-left: 10px;
  margin-top: 18px;
}
.navBarSection .header .logoDiv .logoName .ename {
  font-size: 20px;
  color: var(--Logo-color);
  margin-top: 0;
  margin-left: 0;
}
.navBarSection .header .navBar .navLists {
  color: var(--Text-color);
  display: flex;
  flex-direction: row;
  padding: 0;
}
.navBarSection .header .navBar .navLists .navItem {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  margin-right: 25px;
  transition: transform 0.3s;
  cursor: pointer;
}
.navBarSection .header .navBar .navLists .navItem:hover {
  transform: scale(1.1);
}
.navBarSection .header .navBar .navLists .navItem .NavLink {
  color: var(--Text-color);
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  font-weight: 600;
}
.navBarSection .header .navBar .navLists .navItem .NavLink:hover {
  color: var(--Logo-color);
}
.navBarSection .header .navBar .closeNavbar {
  display: none;
}
.navBarSection .header .toggleNavbar {
  display: none;
}

@media (max-width: 768px) {
  .navBarSection .header {
    display: flex;
    flex-direction: row;
    height: 70px;
  }
  .navBarSection .header .logoDiv .imgLogo {
    background: none;
    height: 50px;
    width: 50px;
    margin-left: 5px;
  }
  .navBarSection .header .logoDiv .logoName {
    margin-left: 10px;
  }
  .navBarSection .header .logoDiv .logoName h1 {
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 10px;
    margin-left: 0px;
  }
  .navBarSection .header .navBar {
    z-index: 1000;
    position: absolute;
    height: -moz-max-content;
    height: max-content;
    padding: 1rem;
    width: 100%;
    top: 0;
    left: -500%;
    box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
    transition: 0.5s ease;
    z-index: 1200;
    background-color: var(--Grey-Bg);
  }
  .navBarSection .header .navBar .navLists {
    display: grid;
    grid-template-columns: 1fr;
    /* Adjust the number of columns as needed */
    /* Adjust the gap between grid items */
    display: flex;
    justify-content: right;
    flex-direction: column;
    text-align: right;
    align-items: end;
    margin-top: 20px;
  }
  .navBarSection .header .navBar .navLists .navItem {
    margin-right: 40px;
  }
  .navBarSection .header .navBar .navLists .navItem .NavLink {
    display: block;
    padding: 1rem 0;
    color: var(--white-color);
    font-size: 0.9rem;
    font-weight: 600;
  }
  .navBarSection .header .navBar .navLists .navItem .NavLink:hover {
    color: var(--Logo-color);
  }
  .navBarSection .header .navBar .closeNavbar {
    display: block;
    position: absolute;
    flex: 1;
    left: 2rem;
    top: 1rem;
    right: 2 rem;
    color: var(--Text-color);
  }
  .navBarSection .header .navBar .closeNavbar:hover {
    color: var(--Logo-color);
  }
  .navBarSection .header .activeNavbar {
    left: 0;
  }
  .navBarSection .header .toggleNavbar {
    display: block;
    color: var(--Text-color);
    font-size: 28px;
    margin-right: 10px;
    cursor: pointer;
  }
  .navBarSection .header .toggleNavbar:hover {
    color: var(--Logo-color);
  }
}/*# sourceMappingURL=Navigationbar.css.map */