:root {
    --Grey-Bg: #1E1E1E;
    --Text-color: #ffffff;
    --Logo-color: #BC0000;

}

.footerSection {
    background-color: var(--Grey-Bg);

    .companyName {
        color: var(--Text-color);
        padding-top: 30px;
        margin-left: 20px;
    }

    .footContent {
        display: flex;
        flex-direction: column;


        .addressDetails {
            display: flex;
            flex-direction: row;
            margin-left: 15px;


            img {
                height: 100%;
            }

            .address {
                margin-left: 20px;
                color: var(--Text-color);

                p {
                    margin-top: 0px;

                }
            }
        }

        .dutyHours {
            margin-bottom: 20px;
            margin-left: 15px;

            .workingHours {
                display: flex;
                flex-direction: row;

                img {
                    height: 100%;
                }


                .timings {
                    margin-left: 20px;
                    color: var(--Text-color);
                }
            }
        }
    }

    .copyright {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-top: 1px solid var(--Text-color);

        p {
            color: var(--Text-color);
            padding-bottom: 40px;
            margin-top: 30px;
            margin-bottom: 0px;
            text-align: center;

            span {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

}

@media (max-width:768px) {
    .copyright {
        p {
            font-size: 15px;

            span {
                font-size: 15px;
            }
        }
    }
}