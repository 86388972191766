:root {
  --Grey-Bg: #1E1E1E;
  --Text-color: #ffffff;
  --Logo-color: #BC0000;
}

.footerSection {
  background-color: var(--Grey-Bg);
}
.footerSection .companyName {
  color: var(--Text-color);
  padding-top: 30px;
  margin-left: 20px;
}
.footerSection .footContent {
  display: flex;
  flex-direction: column;
}
.footerSection .footContent .addressDetails {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}
.footerSection .footContent .addressDetails img {
  height: 3%;
  width: 3%;
}
.footerSection .footContent .addressDetails .address {
  margin-left: 20px;
  color: var(--Text-color);
}
.footerSection .footContent .addressDetails .address p {
  margin-top: 0px;
}
.footerSection .footContent .dutyHours {
  margin-bottom: 20px;
  margin-left: 15px;
}
.footerSection .footContent .dutyHours .workingHours {
  display: flex;
  flex-direction: row;
}
.footerSection .footContent .dutyHours .workingHours img {
  height: 3%;
  width: 3%;
}
.footerSection .footContent .dutyHours .workingHours .timings {
  margin-left: 20px;
  color: var(--Text-color);
}
.footerSection .copyright {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--Text-color);
}
.footerSection .copyright p {
  color: var(--Text-color);
  padding-bottom: 40px;
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
}
.footerSection .copyright p span {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .copyright p {
    font-size: 15px;
  }
  .copyright p span {
    font-size: 15px;
  }
}/*# sourceMappingURL=Footer.css.map */