:root {
  --Grey-Bg: #1E1E1E;
  --Text-color: #ffffff;
  --Logo-color: #BC0000;
}

.mainSection {
  height: 500px;
  background: url(../../assets/Hero-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  filter: blur(none);
}
.mainSection .mainHeading {
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: start;
  margin-left: 60px;
}
.mainSection .mainHeading h1 {
  font-size: 32px;
  font-weight: 600;
  margin-top: 150px;
  color: var(--Text-color);
  filter: blur(none);
}
.mainSection .subMain h2 {
  margin-left: 60px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--Text-color);
  filter: blur(none);
}
.mainSection .subMain p {
  margin-top: 0;
  margin-left: 60px;
  font-weight: 600;
  color: var(--Text-color);
}/*# sourceMappingURL=Main.css.map */